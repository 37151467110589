import React, { Suspense, useRef, useState, useEffect } from 'react';
import { Route, HashRouter } from 'react-router-dom';
import 'src/components/globalVariables.ts';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import './helper.scss';
import 'src/pages/color_var.scss';

// Define the route object structure
interface RouteConfig {
  path: string;
  component: React.ComponentType<any>; // Assuming the component accepts any props
  routes?: RouteConfig[]; // Nested routes if any
}

const App = () => {
  const routes: RouteConfig[] = require('src/routes/zship-routes').default;
  const [themeColor, setThemeColor] = useState<string>("purple");
  const selectRef: any = useRef<HTMLSelectElement>(null);

  const routeComponents = routes.map((route, key) => {
    return (
      <Route exact path={route.path} key={key} render={props => (
        <route.component {...props} routes={route.routes} />
      )} />
    );
  });

  const updateColorTheme = (e: any) => {
    let el = e.target;
    setThemeColor(el.value);
  };

  useEffect(() => {
    const root = document.documentElement;
    let bkgColor, light, slightLight, lighter, fontColor, bkgImg;
    switch (themeColor) {
      case 'purple':
        bkgColor = '#ffffff';
        light = '#242b4c';
        lighter = '#1489ae';
        slightLight = '#E0F0FF';
        fontColor = '#ffff';
        bkgImg = '#adb8ef';
        break;
      case 'green':
        bkgColor = '#ffffff';
        light = '#053715';
        lighter = '#12b446';
        slightLight = '#074a1d';
        fontColor = '#ffff';
        bkgImg = '#9fdfb3';
        break;
      case 'red':
        bkgColor = '#ffffff';
        light = '#81112c';
        lighter = '#c41a44';
        slightLight = '#540f20';
        fontColor = '#ffff';
        bkgImg = '#e1a6b4';
        break;
      case 'blue':
        bkgColor = '#ffffff';
        light = '#25669a';
        lighter = '#3b8ed0';
        slightLight = '#E0F0FF';
        fontColor = '#ffff';
        bkgImg = '#add4f3';
        break;
      default:
        bkgColor = '#ffffff';
        light = '#242b4c';
        lighter = '#1489ae';
        slightLight = '#E0F0FF';
        fontColor = '#ffff';
        bkgImg = '#adb8ef';
        break;
    }
    root?.style.setProperty("--background-color", bkgColor);
    root?.style.setProperty("--light", light);
    root?.style.setProperty("--lighter", lighter);
    root?.style.setProperty("--slightLight", slightLight);
    root?.style.setProperty("--fontColor", fontColor);
    root?.style.setProperty("--bkgImg", bkgImg);

  }, [themeColor]);

  return (
    <HashRouter>
      <div className="wrapper">
        <Suspense fallback={<div>Loading...</div>}>
          {routeComponents}
        </Suspense>
      </div>
    </HashRouter>
  );
}

export default App;
